<template>
  <v-card class="my-5">
    <v-toolbar elevation="5" color="green darken-2" dark>
      <v-btn icon @click="back()" class="pl-2">
        <v-icon x-large color="white">mdi-arrow-left-circle</v-icon>
      </v-btn>
      <v-toolbar-title class="row justify-center">
        <v-toolbar-title>Изменить подборку</v-toolbar-title>
      </v-toolbar-title>
    </v-toolbar>
    <v-row class="mx-3 my-6">
      <v-col cols="12" class="col col-lg-6 col-md-6 sm-6">
        <v-text-field v-model="item.title"
                      clearable
                      dense
                      label="Подборка"
                      outlined
                      rounded/>
      </v-col>
      <v-col cols="12" class="mt-1 col col-lg-6 col-md-6 sm-6">
        <v-slider v-model="item.priority"
                  min="1"
                  max="20"
                  thumb-label="always"
                  thumb-color="blue"
                  :thumb-size="24"
                  track-color="primary"
                  label="Порядок"
                  ticks="always"
                  color="blue"/>
      </v-col>
      <v-col cols="12" class="col col-lg-10 col-md-10 sm-10">
        <v-textarea dense
                    v-model="item.description"
                    rows="3"
                    hide-details
                    label="Описание"
                    outlined
                    rounded/>
      </v-col>
      <v-col cols="12" align-self="end" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-center">
        <v-btn class="mb-1" rounded outlined elevation="3" color="primary" @click="update">
          <span>Сохранить</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mx-3 mt-1">
      <v-col cols="10"></v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <store-add @finished="fetch"/>
      </v-col>
    </v-row>
    <v-row class="ma-3" v-for="store in item.stores" :key="store.id">
      <v-col cols="12" class="col col-lg-3 col-md-3 sm-3">
        <v-text-field :value="getStore(store.store_id)"
                      dense
                      label="Заведение"
                      outlined
                      readonly
                      rounded/>
      </v-col>
      <v-col cols="12" class="col col-lg-1 col-md-1 sm-1">
        <v-text-field v-model="store.priority"
                      dense
                      label="Порядок"
                      outlined
                      readonly
                      rounded/>
      </v-col>
      <v-col cols="12" class="col col-lg-6 col-md-6 sm-6">
        <v-textarea v-model="store.description"
                    dense
                    label="Описание"
                    outlined
                    readonly
                    rows="3"
                    rounded/>
      </v-col>
      <v-col cols="12" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-space-around" align-self="center">
        <store-edit @finished="fetch" :row="store"/>
        <v-btn outlined elevation="5" small class="mb-4" fab color="error" @click="deleteStore(store)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import storeAdd from "./storeAdd.vue";
import storeEdit from "./storeEdit.vue";

export default {
  components: { storeAdd, storeEdit },
  data() {
    return {
      slider: 40,
      item: {}
    }
  },
  mounted() {
    this.$store.dispatch("stores/fetchActive");
    this.$store.dispatch("recommendations/fetch", this.$route.params.id).then(() => {
      this.item = Object.assign({}, this.recommendation);
    })
  },
  computed: {
    stores() {
      return this.$store.getters["stores/active"];
    },
    recommendation() {
      return this.$store.getters["recommendations/item"];
    }
  },
  methods: {
    fetch() {
      this.item = Object.assign({}, this.recommendation);
    },
    update() {
      this.$store.dispatch("recommendations/update", this.item);
      this.$router.push({name: 'recommendations'});
    },
    updateStore(store) {
      this.$store.dispatch("recommendations/updateStore", store);
      this.$router.push({name: 'recommendations'});
    },
    deleteStore(store) {
      this.$confirm(
          "Удалить ?",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("recommendations/deleteStore", store).then(() => {
          this.fetch();
        });
      })
    },
    getStore(id) {
      let name = '';
      if (this.stores && this.stores.length > 0) {
        this.stores.forEach(s => {
          if (s.id === id) {
            name = s.name;
          }
        })
      }
      return name;
    },
    back() {
      this.$router.push({name: 'recommendations'});
    }
  }
}
</script>
