<template>
  <div>
    <v-btn outlined elevation="5" small fab class="mb-4" color="primary" @click="storeUpdateDialog = true">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-dialog v-model="storeUpdateDialog" :max-width="$vuetify.breakpoint.xs ? 374 : 750">
      <v-card shaped class="pa-3">
        <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
          <v-toolbar-title>Изменить</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12">
            <v-autocomplete v-model="item.store_id"
                            :items="stores"
                            chips
                            clearable
                            item-text="name"
                            item-value="id"
                            label="Выберите кафе"
                            persistent-hint
                            rounded
                            solo/>
          </v-col>

          <v-col cols="12">
            <v-slider v-model="item.priority"
                      min="1"
                      max="20"
                      thumb-label="always"
                      thumb-color="blue"
                      :thumb-size="24"
                      track-color="primary"
                      label="Порядок"
                      ticks="always"
                      color="blue"/>
          </v-col>
          <v-col cols="12">
            <v-textarea dense
                        rows="3"
                        hide-details
                        v-model="item.description"
                        label="Описание"
                        outlined
                        rounded/>
          </v-col>
        </v-row>
        <v-row class="justify-space-around pb-3">
          <v-btn rounded outlined elevation="3" color="error" @click="storeUpdateDialog = false">Отменить</v-btn>
          <v-btn rounded outlined elevation="3" v-if="item.store_id" color="primary" @click="updateStore">Сохранить
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    row: Object
  },
  data() {
    return {
      item: {},
      storeUpdateDialog: false
    }
  },
  mounted() {
    this.$store.dispatch("stores/fetchActive");
    this.item = Object.assign({}, this.row);
  },
  computed: {
    stores() {
      return this.$store.getters["stores/active"];
    },
  },
  methods: {
    updateStore() {
      this.$store.dispatch("recommendations/updateStore", this.item)
          .then(() => {
            this.storeUpdateDialog = false;
            this.$emit('finished');
          })
    }
  }
}
</script>
