<template>
  <div>
    <v-btn outlined elevation="5" small fab color="primary" @click="storeAddDialog = true">
      <v-icon>mdi-plus-thick</v-icon>
    </v-btn>
    <v-dialog v-model="storeAddDialog" :max-width="$vuetify.breakpoint.xs ? 374 : 750">
      <v-card shaped class="pa-3">
        <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
          <v-toolbar-title>Добавить заведение</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12">
            <v-autocomplete v-model="item.store_id"
                            :items="stores"
                            chips
                            item-text="name"
                            item-value="id"
                            label="Выберите кафе"
                            persistent-hint
                            rounded
                            solo/>
          </v-col>
          <v-col cols="12">
            <v-slider v-model="item.priority"
                      min="1"
                      max="20"
                      thumb-label="always"
                      thumb-color="blue"
                      :thumb-size="24"
                      track-color="primary"
                      label="Порядок"
                      ticks="always"
                      color="blue"/>
          </v-col>
          <v-col cols="12">
            <v-textarea dense
                        rows="3"
                        hide-details
                        v-model="item.description"
                        label="Описание"
                        outlined
                        rounded/>
          </v-col>
        </v-row>
        <v-row class="justify-space-around pb-3">
          <v-btn rounded outlined elevation="3" color="error" @click="storeAddDialog = false">Отменить</v-btn>
          <v-btn v-if="item.store_id" rounded outlined elevation="3" color="primary" @click="create">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      storeAddDialog: false,
      item: {
        store_id: uuid.Nil,
        priority: 0,
        description: ""
      },
      defaultItem: {
        store_id: uuid.Nil,
        priority: 0,
        description: ""
      }
    }
  },
  mounted() {
    this.$store.dispatch("stores/fetchActive");
  },
  computed: {
    stores() {
      return this.$store.getters["stores/active"];
    }
  },
  methods: {
    create() {
      this.item.recommendation_id = this.$route.params.id;
      this.$store.dispatch("recommendations/createStore", this.item)
          .then(() => {
            this.storeAddDialog = false;
            this.item = this.defaultItem;
            this.$emit('finished');
          })
    }
  }
}
</script>
